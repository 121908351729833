<template>
  <div>
    <app-page-detail page-title="Detail Addendum Contract" page-toolbar-title="Detail Addendum Contract" :record-not-found="notFound">
      <template #toolbar>
        <action-button :data-detail="data_detail" @refresh-detail="refreshDetail()" @callback-delete="goBack()"></action-button>
      </template>
      <v-row>
        <v-col cols="12" lg="9">
          <v-row>
            <v-col cols="12" md="12">
              <card-expansion title="General" class="mb-3" :loading="loading">
                <v-row>
                  <v-col cols="12" md="6">
                    <app-text-field dense readonly v-model="addendum_header.document_no_" placeholder="No." label="No." outlined hide-details class="mb-2"></app-text-field>
                    <app-text-field dense readonly v-model="addendum_header.document_date" type="date" placeholder="Doc. Date" label="Doc. Date" outlined hide-details class="mb-2"></app-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <app-text-field dense readonly v-model="addendum_header.area_code" placeholder="Area Code" label="Area Code" outlined hide-details class="mb-2"></app-text-field>
                    <app-text-field dense readonly v-model="addendum_header.status" placeholder="Status" label="Status" outlined hide-details class="mb-2"></app-text-field>
                  </v-col>
                </v-row>

                <v-divider class="mb-2"></v-divider>

                <v-row>
                  <v-col cols="6" md="6">
                    <app-text-field dense readonly v-model="addendum_header.contract_number" placeholder="Nomor Kontrak" label="Nomor Kontrak" outlined hide-details class="mb-2"></app-text-field>
                    <app-text-field dense readonly v-model="addendum_header.contract_date" placeholder="Tanggal" label="Tanggal" type="date" outlined hide-details class="mb-2"></app-text-field>
                    <app-text-field dense readonly v-model="addendum_header.first_party" placeholder="Pihak Ke Satu" label="Pihak Ke Satu" outlined hide-details class="mb-2"></app-text-field>
                    <app-text-field dense readonly v-model="addendum_header.second_party" placeholder="Pihak Ke Dua" label="Pihak Ke Dua" outlined hide-details class="mb-2"></app-text-field>
                  </v-col>
                  <v-col cols="6" md="6">
                    <app-text-field dense readonly v-model="addendum_header.customer_name" placeholder="Project Owner" label="Project Owner" outlined hide-details class="mb-2"></app-text-field>
                    <app-text-field dense readonly v-model="addendum_header.customer_status" placeholder="Customer Status" label="Customer Status" outlined hide-details class="mb-2"></app-text-field>
                    <app-text-field dense readonly v-model="addendum_header.campaign_no_" placeholder="Campaign No." label="Campaign No." outlined hide-details class="mb-2"></app-text-field>
                    <app-text-field dense readonly v-model="addendum_header.campaign_description" placeholder="Campaign Description" label="Campaign Description" outlined hide-details class="mb-2"></app-text-field>
                  </v-col>
                </v-row>

                <app-text-field dense readonly v-model="addendum_header.scope_of_work" placeholder="Lingkup Pekerjaan" label="Lingkup Pekerjaan" outlined hide-details class="mb-2"></app-text-field>
              </card-expansion>
              <card-expansion title="Detail" class="mb-6" :loading="loading">
                <v-row>
                  <v-col>
                    <table style="width: 100%" border="0" cellspacing="5" cellpadding="0">
                      <tr>
                        <th lg="2" cols="12">
                          <span class="d-block my-1">PERUBAHAN</span>
                        </th>
                        <th lg="5" cols="12">
                          <span class="d-block my-1">SEMULA</span>
                        </th>
                        <th lg="5" cols="12">
                          <span class="d-block my-1">MENJADI</span>
                        </th>
                      </tr>
                      <tr>
                        <td lg="2" cols="12">
                          <span class="d-block my-1">JENIS PERJANJIAN</span>
                        </td>
                        <td lg="5" cols="12">
                          <app-text-field label="Semula" v-model="addendum_header.before_contract_type" hide-details outlined readonly dense></app-text-field>
                        </td>
                        <td lg="5" cols="12">
                          <app-text-field label="Menjadi" v-model="addendum_header.after_contract_type" hide-details outlined readonly dense></app-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td lg="2" cols="12">
                          <span class="d-block my-1">NOMOR PERJANJIAN</span>
                        </td>
                        <td lg="5" cols="12">
                          <app-text-field label="Semula" v-model="addendum_header.before_contract_number" hide-details outlined readonly dense></app-text-field>
                        </td>
                        <td lg="5" cols="12">
                          <app-text-field label="Menjadi" v-model="addendum_header.after_contract_number" hide-details outlined readonly dense></app-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td lg="2" cols="12">
                          <span class="d-block my-1">TGL PERJANJIAN</span>
                        </td>
                        <td lg="5" cols="12">
                          <app-text-field label="Semula" v-model="addendum_header.before_contract_date" type="date" hide-details outlined readonly dense></app-text-field>
                        </td>
                        <td lg="5" cols="12">
                          <app-text-field label="Menjadi" v-model="addendum_header.after_contract_date" type="date" hide-details outlined readonly dense></app-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td lg="2" cols="12">
                          <span class="d-block my-1">HARGA</span>
                        </td>
                        <td lg="5" cols="12">
                          <app-text-field label="Semula" v-model="addendum_header.before_price" type="number" hide-details outlined readonly dense reverse></app-text-field>
                        </td>
                        <td lg="5" cols="12">
                          <app-text-field label="Menjadi" v-model="addendum_header.after_price" type="number" hide-details outlined readonly dense reverse></app-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td lg="2" cols="12">
                          <span class="d-block my-1">WAKTU PENGERJAAN</span>
                        </td>
                        <td lg="5" cols="12">
                          <app-text-field label="Semula" v-model="addendum_header.before_work_duration" hide-details outlined readonly dense></app-text-field>
                        </td>
                        <td lg="5" cols="12">
                          <app-text-field label="Menjadi" v-model="addendum_header.after_work_duration" hide-details outlined readonly dense></app-text-field>
                        </td>
                      </tr>
                    </table>
                  </v-col>
                </v-row>
              </card-expansion>

              <card-expansion title="List Approval" class="mb-3">
                <v-row>
                  <v-col cols="12">
                    <ApprovalList v-bind:data-list="addendum_approval"></ApprovalList>
                  </v-col>
                </v-row>
              </card-expansion>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="3">
          <document-info :addendum_header="addendum_header"></document-info>
          <app-comment :document_id="parseInt(addendum_header.id)" :source_document="addendum_header.source_document" :document_no_="addendum_header.document_no_"></app-comment>
        </v-col>
      </v-row>
    </app-page-detail>
  </div>
</template>

<script>
import ApprovalList from "./components/ApprovalList.vue";
import DocumentInfo from "./components/DocumentInfo.vue";
import ActionButton from "./components/ActionButton.vue";
// import SetCloseDocument from "./components/SetCloseDocument.vue";

export default {
  components: {
    // CommentList,
    ApprovalList,
    DocumentInfo,
    ActionButton,
    // SetCloseDocument,
  },
  data() {
    return {
      id: null,
      loading: false,
      data_detail: {},
      addendum_header: {},
      addendum_detail: [],
      addendum_approval: [],
      loading_save: false,
      campaign_list: [],
      search_campaign: "",
      loading_search_campaign: false,
      selected_campaign: null,

      datatable_detail: {
        loading: false,
        headers: [
          { text: "Deskripsi Barang", value: "description" },
          { text: "Satuan", value: "uom" },
          { text: "Unit", value: "quantity", cellClass: "text-end" },
          { text: "Harga Satuan (Rp)", value: "unit_price", cellClass: "text-end" },
          { text: "Harga Total (Rp)", value: "amount", cellClass: "text-end" },
        ],
      },
      dialogAddDetailData: {},
      dialogEditDetail: false,
      dialogEditDetailData: {},
      dialogEditSelectedIndex: null,
      notFound: false,
    };
  },
  computed: {
    dtId() {
      return parseInt(this.$route.query.id);
    },
  },
  methods: {
    refreshDetail() {
      this.getDetail(true);
    },

    /**
     * getDetail
     * Method untuk mengambil data detail dokumen
     */
    async getDetail(refresh = false) {
      this.loading = true;
      this.notFound = false;
      this.showLoadingOverlay(true);

      if (refresh == true) this.AxiosStorageRemove("GET", "addendum/detail");

      this.showLoadingOverlay(true);

      var config = {
        params: {
          id: this.dtId,
        },
        cacheConfig: true,
      };
      await this.$axios
        .get("addendum/detail", config)
        .then((res) => {
          this.data_detail = res.data.data;
          this.addendum_header = res.data.data.addendum_header;
          this.addendum_approval = res.data.data.addendum_approval;

          this.selected_campaign = this.addendum_header.campaign_no_;

          this.showLoadingOverlay(false);

          this.loading = false;
          this.notFound = false;
        })
        .catch((error) => {
          console.log("error.response", error.response);

          if (error.response.status == 404) {
            this.notFound = true;
          }
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
  },

  beforeMount() {
    if (typeof this.$route.query.id !== "undefined") {
      this.id = parseInt(this.$route.query.id);
    } else {
      this.id = parseInt(this.$route.params.id);
    }
  },

  mounted() {
    this.getDetail(true);
  },
};
</script>
<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  vertical-align: top;
  padding-top: 5px;
}
</style>
